/* eslint-disable */
// this is an auto generated file. This will be overwritten
export const listCurrencies = /* GraphQL */ `
  query ListCurrencies(
    $filter: ModelCurrencyFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listCurrencies(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        name
        imageUrl
        active
        sort
        minAmount
      }
      nextToken
      startedAt
    }
  }
`;
