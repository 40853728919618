import { defineStore } from 'pinia'
import { useSessionStorage } from '@vueuse/core'

export const useCartStore = defineStore({
    id: 'cart',
    state: () => ({
        subtotal: useSessionStorage('cart_subtotal', 0),
        fee: useSessionStorage('cart_fee', 0),
        total: useSessionStorage('cart_total', 0),
        converted: 0,
        currency: useSessionStorage('cart_currency', '')
    }),
    getters: {
        getSubtotal(state) {
            console.log('getSubtotal')
            return state.subtotal.toFixed(2);
        },
        getFee(state) {
            return state.fee.toFixed(2);
        },
        getTotal(state) {
            return state.total.toFixed(2);
        },
        getConverted(state) {
            return state.converted;
        },
        getCurrency(state) {
            return state.currency
        }
    },
    actions: {
        setSubtotal(subtotal) {
            this.subtotal = subtotal;
        },
        setFee(fee) {
            this.fee = fee;
        },
        setTotal(total) {
            this.total = total;
        },
        setConverted(converted) {
            this.converted = converted
        },
        setCurrency(currency) {
            this.currency = currency
        }
    },
})