<template>
  <div v-if="clientLoaded">
    <nav-bar :currentStep="currentView.step" v-if="currentView.page.name !== 'notFound'" />
    <component :is="currentView.page" />
  </div>
  <div v-else-if="currentView.page.name === 'notFound'">
    <component :is="currentView.page" />
  </div>
  <div v-else>
    <div class="spinner-border text-primary" style="width: 3em; height: 3em;" role="status">
      <span class="sr-only"></span>
    </div>
  </div>
</template>

<script>
import Selection from './pages/SelectionPage.vue';
import Summary from './pages/SummaryPage.vue';
import Payment from './pages/PaymentPage.vue';
import Processing from './pages/ProcessingPage.vue';
import Complete from './pages/CompletePage.vue';
import Timeout from "@/pages/TimeoutPage";
import NotFound from './pages/NotFoundPage.vue';
import NavBar from './components/NavBar';
import { API, Auth } from 'aws-amplify';
import { useUserStore } from './stores/UserStore.js';
import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap/dist/js/bootstrap.bundle.min.js";
import 'bootstrap-icons/font/bootstrap-icons.css'
import { useCartStore } from './stores/CartStore';

const routes = {
  '/': { step: 0, page: Selection },
  '/summary': { step: 1, page: Summary },
  '/payment': { step: 2, page: Payment },
  '/processing': { step: 3, page: Processing },
  '/complete': { step: 4, page: Complete },
  '/timeout': { step: -1, page: Timeout }
}

export default {
  name: 'App',
  components: {
    NavBar
  },
  setup() {
    const userStore = useUserStore();
    const cartStore = useCartStore();

    return {
      userStore,
      cartStore
    };
  },
  data() {
    return {
      clientLoaded: false,
      currentPath: window.location.hash
    }
  },
  computed: {
    currentView() {
      return routes[this.currentPath.slice(1) || '/'] || { page: NotFound }
    }
  },
  mounted() {
    window.addEventListener('hashchange', () => {
      this.currentPath = window.location.hash
    })

  },
  async created() {
    console.log('App.vue: Get Client');
    await this.getClient()
    console.log('App.vue: Got Client');
  },
  methods: {
    async getClient() {
      console.log('Client');
      const urlParams = new URLSearchParams(window.location.search);

      if (urlParams.has('token')) {
        try {

          const apiName = 'api91e3e4de';
          const clientData = await API.post(apiName, '/check',
            {
              body: { token: urlParams.get('token') },
              headers: {
                'Content-Type': 'application/json',
              },
            });

          if (clientData !== null && !clientData['_deleted']) {

            await Auth.signIn(clientData.email, clientData.password);

            console.log('Client Data', clientData);
            this.userStore.setToken(urlParams.get('token'));
            this.userStore.setEmail(clientData.email);
            this.cartStore.setSubtotal(clientData.subtotal);
            this.cartStore.setFee(clientData.fee);
            this.cartStore.setTotal(clientData.total);

          } else {
            console.log('Client not found');
            window.location.hash = '#/404';
          }
          this.clientLoaded = true;
        } catch (e) {
          console.log('Check failed Error', e);
          if (e.response.status === 410) {
            window.location.hash = '#/complete'
            this.clientLoaded = true
          } else {
            window.location.hash = '#/404'
          }
        }
      } else {
        console.log('Client not found');
        window.location.hash = '#/404'
      }
    }
  }
}
</script>

<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  margin-top: 30px;
}
</style>
