import { defineStore } from 'pinia'
import { useSessionStorage } from '@vueuse/core'

export const useTransactionStore = defineStore({
    id: 'transaction',
    state: () => ({
        response: useSessionStorage('transaction_response', '{}', {
            serializer: {
                read: (v) => v ? JSON.parse(v) : null,
                write: (v) => JSON.stringify(v),
            }
        }),
    }),
    getters: {
        getResponse(state) {
            return state.response;
        },
        getAmount(state) {
            console.log(state.response);
            return state.response.amount;
        },
        getDateCreated(state) {
            let dateCreated = new Date(state.response.date_created);
            dateCreated.setSeconds(dateCreated.getSeconds() + (state.response.timeout - 50))

            return dateCreated
        },
        getTimeout(state) {
            console.log('TransactionStore: getTimeout')
            let dateCreated = new Date(state.response.date_created);
            dateCreated.setSeconds(dateCreated.getSeconds() + (state.response.timeout - 50))
            const today = new Date();
            console.log('Date Created', dateCreated, 'Today', today, 'State Response Timeout', state.response.timeout, 'Time', dateCreated.getTime() - today.getTime())
            return dateCreated.getTime() - today.getTime();
        },
        getQrCode(state) {
            return state.response.qrcode_url
        },
        getAddress(state) {
            return state.response.address
        },
        getPaymentId(state) {
            return state.response.txn_id
        },
        getVerificationCode(state) {
            const searchParams = new URLSearchParams(state.response.status_url);
            return searchParams.get('key');
        },
        getTxnId(state) {
            console.info(state.response)
            return state.response.txn_id
        }
    },
    actions: {
        setResponse(response) {
            this.response = response;
        },
    },
})
