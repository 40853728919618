<template>
    <section class="step-indicator mb-5 d-flex d-none d-sm-flex">
        <template v-for="(step, index) in steps" :key="step.name">
            <div class="step" :class="{ active: currentStep >= index, completed: currentStep > index }">
                <div class="step-icon">
                    <span class="bi bi-check-lg" v-if="currentStep > index"></span>
                    <span v-else>{{ index + 1 }}</span>
                </div>
                <p>{{ step.name }}</p>
            </div>
            <div class="indicator-line" :class="{ active: currentStep > index }" v-if="index !== 4"></div>
        </template>
    </section>
</template>

<script>
export default {
    name: 'NavBar',
    props: {
        currentStep: {
            type: Number,
            required: true
        }
    },
    data() {
        return {
            steps: [
                { name: 'Selection', 'icon': '' },
                { name: 'Summary', 'icon': '' },
                { name: 'Payment', 'icon': '' },
                { name: 'Processing', 'icon': '' },
                { name: 'Complete', 'icon': '' },
            ]
        }
    }
}
</script>

<style lang="scss">
.step-indicator {
    align-items: center;

    .step-icon {
        height: 50px;
        width: 50px;
        border-radius: 50%;
        background: var(--bs-gray-500);
        font-size: 10px;
        text-align: center;
        color: #ffffff;
        position: relative;
        line-height: 50px;
        font-size: 20px;
    }

    .step {
        display: flex;
        align-items: center;
        flex-direction: column;
        position: relative;
        z-index: 1;

        p {
            text-align: center;
            position: absolute;
            bottom: -40px;
            color: var(--bs-gray-500);
            font-size: 14px;
            font-weight: bold;
        }

        &.active {
            .step-icon {
                background: var(--bs-primary);
            }

            p {
                color:var(--bs-primary);
            }
        }
    }

    .indicator-line {
        width: 100%;
        height: 2px;
        background: var(--bs-gray-500);
        flex: 1;

        &.active {
            background: var(--bs-primary);;
        }
    }

}


@media screen and (max-width: 500px) {
    .step p {
        font-size: 11px;
        bottom: -20px;
    }
}
</style>