import { createPinia } from 'pinia'
import { createApp } from 'vue'
import App from './App.vue'
import { Amplify } from 'aws-amplify';
import AmplifyVue from '@aws-amplify/ui-vue';
import awsExports from './aws-exports';

Amplify.configure(awsExports);
const pinia = createPinia()

createApp(App)
    .use(pinia)
    .use(AmplifyVue)
    .mount('#app')
