<template>
  <h1>Summary</h1>
  <p class="mb-4">Please see a summary of your payment. </p>

  <div class="row p-2 p-sm-5 mb-5">
    <div class="col-12 col-lg-4 mb-4">
      <div class="d-flex justify-content-between pb-2">
        <label>Subtotal</label>
        <span>${{ cartStore.getSubtotal }}</span>
      </div>
      <div class="d-flex justify-content-between pb-4 border-bottom">
        <label>Service Fee</label>
        <span>${{ cartStore.getFee }}</span>
      </div>
      <div class="d-flex justify-content-between pt-2">
        <label>Total</label>
        <span>${{ cartStore.getTotal }}</span>
      </div>
    </div>

    <div class="col-12 col-lg-8 mb-5">
      <div class="m-4">
        <div>
          <button type="button" class="btn btn-primary" @click="nextStep()">Pay<sup>1</sup> {{ this.transactionStore.getAmount }} {{ this.cartStore.getCurrency }}<sup>2</sup></button>
        </div>
        <div class="mt-3">
          <a href="#/" class="link-dark">back</a>
        </div>
      </div>
    </div>

    <div class="row-cols-1">
      <sup>1</sup> I understand any refund will be available in a voucher only for services on the AnyTask™ Platform.
    </div>
    <div class="row-cols-1">
      <sup>1.1</sup> I understand it's my responsibility to provide an adequate fee for the transaction to process within 8 hours.
    </div>
  </div>


  <div class="fixed-bottom bg-light small p-2 text-danger">
    <sup>2</sup> Your price will be held for <vue-countdown class="d-inline-block" @end="update()" :time="getRemainingTime()" v-slot="{ hours, minutes, seconds }">{{ hours }}h, {{ minutes }}m, {{ seconds }}s</vue-countdown>, after that it will be recalculated with the prevailing exchange rate
  </div>
</template>

<script>
import { API, Auth } from 'aws-amplify';
import { useCartStore } from '../stores/CartStore.js';
import { useTransactionStore } from '../stores/TransactionStore.js'
import VueCountdown from '@chenfengyuan/vue-countdown';
import {useUserStore} from "@/stores/UserStore";

export default {
  name: 'SummaryPage',
  setup() {
    const userStore = useUserStore();
    const cartStore = useCartStore();
    const transactionStore = useTransactionStore();

    return { userStore, cartStore, transactionStore };
  },
  components: {
    VueCountdown
  },
  methods: {
    nextStep() {
      window.location.href = '#/payment';
    },
    getRemainingTime() {
      const today = new Date();
      return this.transactionStore.getDateCreated.getTime() - today.getTime()
    },
    async update() {
      const apiName = 'api91e3e4de';
      const path = '/transaction';
      const myInit = { // OPTIONAL
        body: {
          id: this.userStore.getToken,
          currency: this.cartStore.getCurrency
        },
        headers: {
          'Content-Type': 'application/json',
          Authorization: (await Auth.currentSession()).getAccessToken().getJwtToken(),
        },
      };

      await API.post(apiName, path, myInit).then((resp) => {
        this.transactionStore.setResponse(resp);
      })
    }
  }
}
</script>
