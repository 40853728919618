/* eslint-disable */
// this is an auto generated file. This will be overwritten
export const onUpdateTransactionById = /* GraphQL */ `
  subscription OnUpdateTransactionById($id: String!) {
    onUpdateTransactionById(id: $id) {
      id
      payment_status
    }
  }
`;

export const onCreateCurrency = /* GraphQL */ `
  subscription OnCreateCurrency($filter: ModelSubscriptionCurrencyFilterInput) {
    onCreateCurrency(filter: $filter) {
      id
      name
      imageUrl
      active
      sort
      minAmount
    }
  }
`;
export const onUpdateCurrency = /* GraphQL */ `
  subscription OnUpdateCurrency($filter: ModelSubscriptionCurrencyFilterInput) {
    onUpdateCurrency(filter: $filter) {
      id
      name
      imageUrl
      active
      sort
      minAmount
    }
  }
`;
export const onDeleteCurrency = /* GraphQL */ `
  subscription OnDeleteCurrency($filter: ModelSubscriptionCurrencyFilterInput) {
    onDeleteCurrency(filter: $filter) {
      id
      name
      imageUrl
      active
      sort
      minAmount
    }
  }
`;
