import { defineStore } from 'pinia'
import { useSessionStorage } from '@vueuse/core'

export const useUserStore = defineStore({
    id: 'user',
    state: () => ({
        accessToken: useSessionStorage('user_access_token', ''),
        token: useSessionStorage('user_token', ''),
        email: useSessionStorage('user_email', '')
    }),
    getters: {
        getAccessToken(state) {
            return state.accessToken;
        },
        getToken(state) {
            return state.token;
        },
        getEmail(state) {
            return state.email;
        }
    },
    actions: {
        setAccessToken(accessToken) {
            this.accessToken = accessToken
        },
        setToken(token) {
            this.token = token;
        },
        setEmail(email) {
            this.email = email;
        }
    },
})