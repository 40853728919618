<template>
  <h1>Payment</h1>
  <p class="mb-2 mb-sm-0">If you already have the CoinPayments app, you can scan the QR code below.</p>
  <p class="mb-2 mb-sm-0">Alternatively, copy the wallet address below into your crypto wallet and manually send the payment.</p>
  <p class="mb-2 mb-sm-0">Once your payment has been sent, please confirm this below by clicking "Payment Sent".</p>
  <p class="mb-2 mb-sm-0">For extra security, please keep your verification code safe in case you need it later.</p>
  <p>Please note, blockchain fees may apply.</p>
  <div class="mb-4">
    <div class="row">
      <div class="col-12 col-sm-3 align-self-center">
        <img :src="transactionStore.getQrCode" class="img-fluid"/>
        <span class="d-block d-block mb-2 mb-sm-0">Time Remaining:
          <vue-countdown class="d-inline-block" @end="timeout" :time="getRemainingTime()" v-slot="{ hours, minutes, seconds }">{{ hours }}h, {{ minutes }}m, {{ seconds }}s</vue-countdown>
        </span>
      </div>
      <div class="col-12 col-sm-9 text-bg-light text-start">
        <div class="mb-2 mt-3">
          <label class="form-label">Amount</label>
          <div class="input-group mb-3">
            <input type="text" class="form-control" :value="transactionStore.getAmount" readonly="readonly">
            <button class="btn btn-outline-secondary" type="button" @click="copyToClipBoard('amount')">{{
                this.cartStore.getCurrency
              }}
            </button>
          </div>
        </div>
        <div class="mb-2">
          <label class="form-label">Address</label>
          <div class="input-group">
            <input class="form-control user-select-all" type="text" readonly="readonly"
                   :value="transactionStore.getAddress"/>
            <button class="btn btn-outline-secondary" type="button"
                    @click="copyToClipBoard('address')">Copy
            </button>
          </div>
        </div>
        <div class="mb-2">
          <label class="form-label">Payment Id</label>
          <div class="input-group">
            <input class="form-control" type="text" readonly="readonly"
                   :value="transactionStore.getPaymentId"/>
            <button class="btn btn-outline-secondary" type="button"
                    @click="copyToClipBoard('paymentId')">Copy
            </button>
          </div>
        </div>
        <div class="mb-3">
          <label class="form-label">Verification Code <small class="d-block d-sm-inline">(save in case there are any issues with your payment)</small></label>
          <div class="input-group">
            <input class="form-control" type="text" readonly="readonly"
                   :value="transactionStore.getVerificationCode"/>
            <button class="btn btn-outline-secondary" type="button"
                    @click="copyToClipBoard('verificationCode')">Copy
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div>
    <div>
      <button type="button" class="btn btn-primary" @click="nextStep()">Payment Sent</button>
    </div>
    <div class="mt-3">
      <a href="#/summary" class="link-dark">back</a>
    </div>
  </div>
</template>

<script>
import {useTransactionStore} from '../stores/TransactionStore.js'
import {useCartStore} from '../stores/CartStore.js';
import VueCountdown from '@chenfengyuan/vue-countdown';

export default {
  name: 'PaymentPage',
  setup() {
    const transactionStore = useTransactionStore();
    const cartStore = useCartStore();

    return {cartStore, transactionStore};
  },
  data() {
    return {
      clientConfirmed: false
    }
  },
  components: {
    VueCountdown
  },
  methods: {
    nextStep() {
      window.location.href = '#/processing'
    },
    getRemainingTime() {
      const today = new Date();
      return this.transactionStore.getDateCreated.getTime() - today.getTime()
    },
    copyToClipBoard(type) {
      switch (type) {
        case "amount":
          navigator.clipboard.writeText(this.transactionStore.getAmount)
          break;
        case "address":
          navigator.clipboard.writeText(this.transactionStore.getAddress)
          break;
        case "paymentId":
          navigator.clipboard.writeText(this.transactionStore.getPaymentId)
          break;
        case "verificationCode":
          navigator.clipboard.writeText(this.transactionStore.getVerificationCode)
          break;
      }
    },
    timeout() {
      window.location.href = '#/timeout'
    }
  }
}
</script>
