<template>
  <h1>Select a cryptocurrency</h1>
  <p>By selecting a cryptocurrency below, I consent for CoinPayments to use my email address for payment processing and
    to contact me regarding any payment issues.</p>

  <div class="d-flex justify-content-center flex-wrap mb-4">
    <template v-if="currencies.length > 0">
      <div v-for="item in getCurrency" :key="item.id" class="btn btn-dark m-2" :class="{disabled: item.disabled}" @click="nextStep($event, item.name)">
        <img :src="item.imageUrl" style="width:25px; height:25px" class="me-2 pe-none"/>
        <span class="pe-none">{{ item.name }}</span>
        <div class="ms-2 spinner spinner-grow spinner-grow-sm d-none pe-none" role="status">
          <span class="visually-hidden">Loading...</span>
        </div>
      </div>
    </template>
    <template v-else>
      <div class="placeholder-glow" v-for="n in 5" :key="n">
        <span class="placeholder rounded m-2" style="width: 95px; height: 35px;">{{ n }}</span>
      </div>
    </template>
  </div>

  <div class="d-flex justify-content-center flex-wrap mb-4">
    <div class="alert alert-primary d-flex align-items-center" role="alert" v-if="displayDisableMessage">
      <i class="bi bi-info-circle-fill me-2"></i>
      <div>The price of your task does not meet the minimum spend for all coins</div>
    </div>
  </div>

  <div class="align-items-center justify-content-center text-bg-danger toast w-100" :class="{show: isDown}">
    <div class="d-flex">
      <div class="toast-body">
        Sorry looks like the CoinPayments is currently down, Please select a different payment option or try again latter.
      </div>
    </div>
  </div>
</template>

<script>
import {API, Auth} from 'aws-amplify';
import {listCurrencies} from '../graphql/queries';
import {onCreateCurrency, onUpdateCurrency} from '../graphql/subscriptions';
import {useCartStore} from '../stores/CartStore.js';
import {useUserStore} from '../stores/UserStore.js';
import {useTransactionStore} from '../stores/TransactionStore.js'

export default {
  name: 'SelectionPage',
  setup() {
    const userStore = useUserStore();
    const cartStore = useCartStore();
    const transactionStore = useTransactionStore();

    return {
      userStore,
      transactionStore,
      cartStore
    };
  },
  async created() {
    await this.handleCurrencies();
    this.subscribe();
  },
  data() {
    return {
      currencies: [],
      onCreateCurrencySubscription: null,
      OnUpdateCurrencySubscription: null,
      displayDisableMessage: false,
      isDown: false
    }
  },
  unmounted() {
    if (this.onCreateCurrencySubscription) {
      this.onCreateCurrencySubscription.unsubscribe();
      this.onCreateCurrencySubscription = null
    }
    if (this.OnUpdateCurrencySubsctipion) {
      this.OnUpdateCurrencySubsctipion.unsubscribe();
      this.OnUpdateCurrencySubsctipion = null
    }
  },
  methods: {
    nextStep(event, currency) {
      const spinner = event.target.querySelector('.spinner');
      spinner.classList.remove('d-none')
      this.cartStore.setCurrency(currency);
      this.getTransaction().then((resp) => {
        this.transactionStore.setResponse(resp);
        window.location.href = '#/summary';
      }).catch(e => {
        if (e.response.status === 422) {
          // continue regardless of error
        } else if (e.response.status === 503) {
          this.isDown = true;
        }
      }).finally(() => {
        spinner.classList.add('d-none');
      });
    },
    async handleCurrencies() {
      const currencies = await API.graphql({
        query: listCurrencies
      });
      this.currencies = currencies.data.listCurrencies.items.filter((item) => !item['_deleted']);
    },
    subscribe() {
      this.onCreateCurrencySubscription = API.graphql({
        query: onCreateCurrency
      })
          .subscribe({
            next: (eventData) => {
              let currency = eventData.value.data.onCreateCurrency;

              if (this.currencies.some(item => item.name === currency.name)) return; // remove duplications
              this.currencies = [...this.currencies, currency];
            }
          })

      this.OnUpdateCurrencySubsctipion = API.graphql({
        query: onUpdateCurrency
      })
          .subscribe({
            next: (eventData) => {
              let currency = eventData.value.data.onUpdateCurrency;
              let objIndex = this.currencies.findIndex((obj => obj.id === currency.id))
              this.currencies[objIndex] = currency
            }
          })
    },
    async getTransaction() {
      const apiName = 'api91e3e4de';
      const path = '/transaction';
      const myInit = { // OPTIONAL
        body: {
          id: this.userStore.getToken,
          currency: this.cartStore.getCurrency
        },
        headers: {
          'Content-Type': 'application/json',
          Authorization: (await Auth.currentSession()).getAccessToken().getJwtToken(),
        },
      };

      return await API.post(apiName, path, myInit);
    }
  },
  computed: {
    getCurrency() {
      let temp = this.currencies
      temp = temp.filter((item) => {
        if (item.minAmount === null) {
          item.disabled = false
        } else {
          item.disabled = this.cartStore.getTotal < item.minAmount
        }

        if (item.disabled) {
          this.displayDisableMessage = true
        }

        return item.active
      })

      return temp.sort((a, b) => {
        if (a.sort < b.sort) {
          return -1
        }
        if (a.sort > b.sort) {
          return 1
        }
        return 0
      });
    }
  }
}
</script>
