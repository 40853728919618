<template>
  <div>
    <h1 class="text-danger">Timed Out</h1>
    <p>This payment has timed out ether due to lack of confirms before the time limit or due to not sending enough funds. If you have sent any funds you will receive an email from CoinPayments to claim them within 8 hours of confirming.</p>
    <hr>
    <p class="small">If you have not received any communication after 8 hours, please contact <a href="https://www.coinpayments.net/supwiz" target="_blank">CoinPayments</a></p>
    <div class="d-flex justify-content-center">
      <ol class="small text-start">
        <li>The transaction ID: <strong>{{ transactionStore.getTxnId }}</strong></li>
        <li>A payment address to send the funds to</li>
        <li>This verification code: <strong>{{ transactionStore.getVerificationCode }}</strong> to verify that you are the transaction sender</li>
      </ol>
    </div>
  </div>
</template>

<script>
import {useTransactionStore} from "@/stores/TransactionStore";

export default {
  name: 'timeoutPage',
  setup() {
    const transactionStore = useTransactionStore();

    return { transactionStore };
  },
}
</script>
