<template>
  <h1>Processing</h1>
  <div class="mb-4">
    <p>Your payment is currently being processed. Most transactions are processed within a few minutes, but this could take longer. Please do not refresh the page.</p>
  </div>

  <div class="progress rounded-pill" style="height: 50px">
    <div class="progress-bar progress-bar-striped progress-bar-animated" ref="elaspedTime" role="progressbar" aria-valuemin="0" aria-valuemax="100"></div>
  </div>

  <div>
    <div class="mt-3">
      <a href="#/payment" class="link-dark">back</a>
    </div>
  </div>
</template>

<script>
import {API} from 'aws-amplify';
import {onUpdateTransactionById} from '@/graphql/subscriptions';
import {useTransactionStore} from '../stores/TransactionStore.js'

export default {
  name: 'ProcessingPage',
  setup() {
    const transactionStore = useTransactionStore();

    return {transactionStore};
  },
  data: () => ({
    minutes: 570,
    countdown: null,
    listener: null
  }),
  mounted() {
    this.startTimer();
    this.subscribe();
  },
  methods: {
    startTimer() {
      let seconds = this.minutes // initial time
      let et = this.$refs.elaspedTime
      et.style.width = '0%'
      this.countdown = setInterval(() => {
        this.minutes--;
        et.style.width = ((seconds - this.minutes) * 100) / seconds + '%'
        if (this.minutes === 0) {
          this.stopTimer();
        }
      }, 1000);
    },
    stopTimer() {
      clearInterval(this.countdown);
    },
    subscribe() {
      this.listener = API.graphql({
        query: onUpdateTransactionById,
        variables: {
          id: this.transactionStore.getTxnId
        }
      })
          .subscribe({
            next: (eventData) => {
              let transaction = eventData.value.data.onUpdateTransactionById;
              if (transaction !== null) {
                if (transaction.payment_status === 2) {
                  this.stopTimer();
                  setTimeout(() => {
                    window.location.href = '#/complete';
                  }, 120000)
                }
              }
            }, error: (error) => {
              console.warn(error);
            }
          });
    },
  },
  unmounted() {
    this.stopTimer()
    this.listener.unsubscribe()
  }
}
</script>
